<template>
  <div>
    <buildings/>
    <info-popup @cancel="hideInfo=true" v-if="!hideInfo"/>
  </div>
</template>

<script>

import Buildings from "@/components/device/Buildings";
import InfoPopup from "@/components/dashboard/InfoPopup";

export default {
  components: {
    Buildings,
    InfoPopup
  },
  data() {
    return {
      hideInfo: false
    };
  },
  created() {
    this.hideInfo = localStorage.getItem('infoPopupHide') === 'true'
  },
  methods: {},
};
</script>

<style scoped lang="scss">
</style>
