<template>
  <page>
    <div slot="content" class="p-4">
      <m-data-table :columns="tableColumns"
                    :data-source="buildings"
                    :searchColumns="['name']"
                    rowKey="id"
                    class="m-table"
      >
        <template v-slot:record="{record}">
          <td v-for="field in tableColumns">
            <template v-if="field.dataIndex==='name'">
              <link-badge link="building-devices" :params="{id: record['id']}" :title="record['name']"/>
            </template>
          </td>
        </template>
      </m-data-table>
    </div>
  </page>
</template>

<script>

const tableColumns = [
  {
    title: 'Ēkas nosaukums, adrese',
    dataIndex: 'name'
  },
]

export default {
  name: "Buildings",
  data() {
    return {
      baseUrl: '/api/building/',
      tableColumns,
      buildings: [],
    }
  },
  async created() {
    await this.loadBuildings();
  },
  methods: {
    async loadBuildings() {
      const resp = await this.$http.get(this.baseUrl + 'list');
      this.buildings = resp.data
    },
  }
}
</script>

<style scoped lang="scss">

</style>
