<template>
  <a-modal title="Uzmanību"
           visible="true"
           :footer="null"
           @cancel="$emit('cancel')"
           :maskClosable="false"
  >
    <p style="color: #1890ff;"><b>Godātie apmeklētāji!</b></p>

    <p>Portālā Jūs varat iepazīties ar aktuāliem gaisa kvalitātes mērījumiem:
    <ul class="list-info">
      <li>Latvijas vispārējās izglītības (izņemot privātās) iestāžu kabinetos;</li>
      <li>profesionālās izglītības iestāžu kabinetos</li>
      <li>ilgstošas sociālās aprūpes un rehabilitācijas institūciju (kurām noslēgts līgums ar valsti vai pašvaldību par pakalpojumu sniegšanu)
        koplietošanas telpās.
      </li>
    </ul>

    <p>Gaisa kvalitātes mērītāji uzstādīti atbilstoši iestāžu sniegtajai informācijai, ņemot vērā telpu pielietojumu un noslogojumu.</p>

    <p>Gaisa kvalitāte tiek mērīta pēc šādiem parametriem:
    <ul class="list-info">
      <li>CO<sub>2</sub> saturs gaisā (ppm)</li>
      <li>Gaisa temperatūra (<sup>o</sup>C)</li>
      <li>Gaisa relatīvais mitrums (%)</li>
    </ul>

    <p>Detalizēta informācija par portālā pieejamiem datiem ir apskatāma sadaļā "Kā lasīt mērījumus?"</p>

    <b>
      <a-checkbox v-model="dontShow"/>
      Vairs nerādīt.</b>
    <div class="ant-modal-footer">
      <div>
        <a-button type="primary" @click="closeDialog">Labi</a-button>
      </div>
    </div>


  </a-modal>
</template>

<script>
export default {
  name: "InfoPopup",
  data() {
    return {
      dontShow: false
    }
  },
  methods: {
    closeDialog() {
      if (this.dontShow) {
        localStorage.setItem('infoPopupHide', 'true')
      } else {
        localStorage.removeItem('infoPopupHide')
      }
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
