import { render, staticRenderFns } from "./Buildings.vue?vue&type=template&id=7b6bd3e6&scoped=true&"
import script from "./Buildings.vue?vue&type=script&lang=js&"
export * from "./Buildings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b6bd3e6",
  null
  
)

export default component.exports